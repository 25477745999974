import Image from '@react/shared/components/Image';
import Rating from '@sly/frontend/react/shared/components/Rating';
import clsxm from 'sly/lib/clsxm';

const Quote = ({
  quote,
  author,
  relation,
  avatar,
  stars = 5,
  date,
}: {
  quote: string;
  author: string;
  relation: string;
  avatar?: any;
  stars?: number;
  date?: string;
}) => {
  return (
    <div
      className={clsxm(
        'h-fit items-start space-y-4 rounded-xl border border-slate-lighter-90 bg-white p-6 shadow-subtle'
      )}
    >
      <div className='flex justify-between'>
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        <Rating value={stars} />
        <p className='font-b-s text-slate-lighter-40'>{date}</p>
      </div>
      <p className='font-b-m text-start'>{quote}</p>
      <div className='flex items-center gap-4'>
        {!!avatar && (
          <Image
            src={avatar?.url || avatar}
            alt={`${author} - ${relation}`}
            className='h-12 w-12 rounded-full object-cover'
            width={500}
            height={300}
            layout='raw'
          />
        )}
        <div>
          <p className='font-t-xxs-azo text-start'>{author}</p>
          <p className='font-b-s text-start'>{relation}</p>
        </div>
      </div>
    </div>
  );
};

export default Quote;
