import Image from '@react/shared/components/Image';
import Rating from '@react/shared/components/Rating';
import { leadSourceMap } from '@sly/core/constants/uuidActions';
import { ConversionSchemaType } from '@sly/frontend/common/helpers/transformGwizzyCommunity';
import Cta from '@sly/frontend/react/cta/Cta';
import { Checkmark } from '@sly/icons/react';
import { useRouter } from 'next/router';
import { assetPath } from 'sly/lib/path';
import { Button } from 'sly/system';

const reasons = [
  'Independent professional senior living advisors',
  'Expert insights and knowledge on your local communities',
  '100% free service for families',
];

const SeniorlyAgents = ({ hideJoinButton = false }) => {
  const { query } = useRouter();
  return (
    <section className='px-8 pt-14 pb-12'>
      <div className='mx-auto flex max-w-5xl flex-col items-start gap-12 sm:flex-row'>
        <div className='order-2 w-full space-y-8 sm:order-1 sm:w-1/2'>
          <div>
            <p className='font-b-xs font-bold tracking-wider text-viridian-base'>
              CONNECT WITH AN EXPERT
            </p>
            <h2 className='font-t-l mt-4 max-w-sm'>
              Your trusted partner, every step of the way.
            </h2>
            <p className='font-b-m mt-4'>
              From understanding your needs to finding the perfect match,
              Seniorly’s local advisors guide you with compassion and care.
            </p>
            <div className='mt-6 space-y-2'>
              {reasons.map((reason, index) => (
                <div key={index} className='flex gap-4'>
                  <Checkmark className='text-viridian-base' />
                  <p className='font-b-m'>{reason}</p>
                </div>
              ))}
            </div>
          </div>
          <div className='space-y-3'>
            <Cta
              showLocationStep={true}
              params={query}
              ctaProps={{
                type: 'gwizzy',
                text: 'Connect me with an advisor',
              }}
              mode={{
                cta: 'speakExpert',
                entry: 'homepage',
                leadSource: leadSourceMap.HomepageSpeakWithExpertLS,
              }}
              isFullScreen
              conversionSchemaOverride={ConversionSchemaType.PaidAd}
              className='font-t-m-azo w-full sm:w-fit'
              startLink='/wizards/assessment'
              eventProps={{
                text: 'connect me with advisor',
                location: 'homepage',
              }}
              submitText='Connect me with an advisor'
            />
            {!hideJoinButton && (
              <Button
                className='font-t-m-azo w-full px-4 sm:w-fit'
                variant='outlined'
                href='/partners/agents'
                target='_blank'
                eventProps={{
                  location: 'homepage',
                  text: 'join as seniorly advisor',
                }}
              >
                Join as a Seniorly Local Advisor
              </Button>
            )}
          </div>
        </div>
        <div className='relative order-1 w-full sm:order-2 sm:w-1/2'>
          <Image
            src={assetPath(
              'images/640x/d93abb32f7719aeb46b254463debe818/_Caring_Heart_Solution.jpg'
            )}
            alt='Seniorly Advisors'
            width={500}
            height={500}
            layout='raw'
            className='w-full rounded-lg bg-slate-lighter-97'
          />
          <div className='absolute bottom-2 left-2 flex flex-col justify-center gap-1 rounded-lg border-b border-b-slate-lighter-90 bg-white p-3 shadow-subtle'>
            <div>
              <p className='font-t-xxs-azo text-black'>Donlyn Young</p>
              <p className='font-b-xs'>Seniorly Local Advisor</p>
            </div>
            <Rating value={5} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default SeniorlyAgents;
